.rdg-row:not(:hover) div.modified {
    background-color: var(--primary-110) !important;
}

.rdg-row:hover div.modified {
    background-color: var(--primary-120) !important;
}

.rdg-row:hover div.stripe,
.rdg-row:not(:hover) div.stripe.disabled {
    background-color: var(--purple-110) !important;
}

.rdg-row:hover div.stripe.disabled {
    background-color: var(--purple-120) !important;
}

.rdg-row:hover div.green,
.rdg-row:not(:hover) div.green {
    border: solid 3px var(--secondary-100) !important;
}

.rdg-row:hover div.green {
    border: solid 3px var(--secondary-110) !important;
}

.rdg-row:hover div.yellow,
.rdg-row:not(:hover) div.yellow {
    border: solid 3px var(--yellow-110) !important;
}

.rdg-row:hover div.yellow {
    border: solid 3px var(--yellow-120) !important;
}

.rdg-row:hover div.orange,
.rdg-row:not(:hover) div.orange {
    border: solid 3px var(--orange-110) !important;
}

.rdg-row:hover div.orange {
    border: solid 3px var(--orange-120) !important;
}

.rdg-row:hover div.red,
.rdg-row:not(:hover) div.red {
    border: solid 3px var(--red-110) !important;
}

.rdg-row:hover div.red {
    border: solid 3px var(--red-120) !important;
}

.rdg-row:not(:hover) div.cut-down {
    background-color: var(--red-100) !important;
}

.rdg-row div.cut-down {
    background-color: var(--red-110) !important;
}

.rdg-row div.expandable,
.rdg-row div.subheader,
.rdg-row div.disabled,
.rdg-row div.modified,
.rdg-row div.stripe,
.rdg-row div.red,
.rdg-row div.orange,
.rdg-row div.yellow,
.rdg-row div.green,
.rdg-row div.cut-down {
    padding-top: 1px !important;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0, ;
    margin-left: -8px;
    margin-right: -8px;
    padding-left: 8px;
    padding-right: 8px;
}

.rdg-cell-selected div.expandable,
.rdg-cell-selected div.subheader,
.rdg-cell-selected div.disabled,
.rdg-cell-selected div.modified,
.rdg-cell-selected div.cut-down {
    margin: 2px -6px !important;
    height: calc(100% - 4px) !important;
    padding-left: 6px !important;
    padding-right: 6px !important;
}

.rdg-row div.subheader:not(.total) {
    font-weight: bolder;
}

.rdg-row .rdg-cell-value {
    flex-basis: 100%;
}

.rdg-cell-drag-handle {
    background-color: var(--primary-100) !important;
}

.rdg-cell[aria-selected=true] {
    box-shadow: inset 0 0 0 2px var(--primary-100) !important;
}

.rdg-text-editor:focus {
    border-color: var(--primary-100) !important;
}

#admin-panel__grid .rdg-row div.disabled,
#admin-panel__grid .rdg-row div.modified,
#admin-panel__grid .rdg-row div.stripe,
#admin-panel__grid .rdg-row div.red,
#admin-panel__grid .rdg-row div.orange,
#admin-panel__grid .rdg-row div.yellow,
#admin-panel__grid .rdg-row div.green,
#admin-panel__grid .rdg-row div.cut-down {
    justify-content: center;
}

.rdg-cell {
    border-bottom-width: 2px !important;
    border-right-width: 2px !important;
}

.full-width-input-item,
.full-width-input-item .input,
.full-width-input-item input {
    width: 100% !important;
}

.full-width-input-item.element-input .input,
.full-width-input-item.element-input input {
    width: 75% !important;
}

.full-width-input-item.element-input .input:nth-child(2),
.full-width-input-item.element-input input:nth-child(2) {
    width: 25% !important;
}

.full-width-input-item input,
.full-width-input-item input:focus,
.full-width-input-item select,
.full-width-input-item select:focus {
    border: solid 1px var(--black-60) !important;
    border-radius: 0px !important;
    border-bottom: none !important;
    height: 30px;
    text-align: left !important;
    text-align-last: left !important;
    padding-right: 150px !important;
}

.rdg-filter-row .rdg-cell {
    padding: 0 5px !important;
}

.rdg-filter {
    height: 28px !important;
}

#action-table__segment .rdg,
#action-table__segment>div>div:nth-last-child(2) .rdg-cell {
    border-bottom-color: transparent !important;
}

#action-table__segment {
    overflow: hidden;
}

#action-table__segment .rdg {
    margin-bottom: -2px !important;
}

.expand-formatter {
    position: absolute;
    right: 8px;
    display: table;
    height: 100%;
}

.expand-formatter>span {
    display: table-cell;
    vertical-align: middle;
    cursor: pointer;
}

.expandable>.rdg-cell-value {
    max-width: 90%;
    overflow: hidden;
}

.rdg-row.highlighted .rdg-cell {
    background-color: transparent !important;
}

.rdg-text-editor {
    width: 100%;
    height: 100%;
    padding-left: 8px;
    padding-right: 8px;
}

.rdg-cell-editing {
    border: none;
    box-shadow: none;
}