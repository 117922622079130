#detail-grid .card {
    width: auto;
    margin: 20px 10px;
}

.detail-masonry {
    display: flex;
    width: auto;
}

.detail-masonry__column {
    margin-top: -5px;
}

#detail-grid .card .header {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bolder;
    font-size: 18pt;
}

#detail-grid .card .header svg {
    font-size: 25pt;
    margin: 5px 15px 5px 0;
}

#detail-grid .row {
    padding: 3px 8px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

#detail-grid .row label {
    margin-right: 4px;
}

#detail-grid .row label, #detail-grid .row .ui.label {
    font-weight: bold;
    font-size: 14pt;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#detail-grid span, #detail-grid li {
    font-size: 12pt;
}

#detail-grid .row span {
    margin-left: 5px;
    padding-top: 1px;
}

#tags {
    display: flex; 
    overflow-x: auto;
    width: fit-content;
    max-width: 100%;
    margin: 0 auto;
}

#tags::-webkit-scrollbar, #tags::-webkit-scrollbar-track, #tags::-webkit-scrollbar-thumb, #tags::-webkit-scrollbar-thumb:hover {
    height: 4px;
}