:root {
    --primary-100: #68bd46;
    --secondary-100: #335f22;
    --black-100: #141414;
    --grey-100: #7d7d7d;
    --white-100: #FCFCFC;
    --red-100: #c83030;
    --green-100: #68bd46;
    --blue-100: #2185d0;
    --yellow-100: #daa817;
    --orange-100: #d06a28;
    --pink-100: #ce4894;
    --purple-100: #7250bc;
    --brown-100: #a2653d;
}

@font-face {
    font-family: Sofia;
    src: url(../resources/fonts/Sofia\ Pro\ Regular\ Az.otf);
}
/* BOUTONS */
/* Primaire */
.button--primary {
    background-color: var(--primary-100) !important;
}

.button--primary:hover:not(:active) {
    background-color: var(--primary-110) !important;
}

.button--primary:active, .button--active.primary {
    background-color: var(--primary-120) !important;
}

/* Secondaire */
.button--secondary {
    background-color: var(--secondary-100) !important;
}

.button--secondary:hover:not(:active) {
    background-color: var(--secondary-110) !important;
}

.button--secondary:active, .button--active.secondary {
    background-color: var(--secondary-120) !important;
}

/* Gris */
.ui.button.grey {
    background-color: var(--grey-100) !important;
}

.ui.button.grey:hover:not(:active) {
    background-color: var(--grey-110) !important;
}

.ui.button.grey:active, .ui.button.active.grey {
    background-color: var(--grey-120) !important;
}

/* Rouge */
.ui.button.red {
    background-color: var(--red-100) !important;
}

.ui.button.red:hover:not(:active) {
    background-color: var(--red-110) !important;
}

.ui.button.red:active, .ui.button.active.red {
    background-color: var(--red-120) !important;
}

/* Vert */
.ui.button.green {
    background-color: var(--green-100) !important;
}

.ui.button.green:hover:not(:active) {
    background-color: var(--green-110) !important;
}

.ui.button.green:active, .ui.button.active.green {
    background-color: var(--green-120) !important;
}

/* Bleu */
.ui.button.blue {
    background-color: var(--blue-100) !important;
}

.ui.button.blue:hover:not(:active) {
    background-color: var(--blue-110) !important;
}

.ui.button.blue:active, .ui.button.active.blue {
    background-color: var(--blue-120) !important;
}

/* Jaune */
.ui.button.yellow {
    background-color: var(--yellow-100) !important;
}

.ui.button.yellow:hover:not(:active) {
    background-color: var(--yellow-110) !important;
}

.ui.button.yellow:active, .ui.button.active.yellow {
    background-color: var(--yellow-120) !important;
}

/* Orange */
.ui.button.orange {
    background-color: var(--orange-100) !important;
}

.ui.button.orange:hover:not(:active) {
    background-color: var(--orange-110) !important;
}

.ui.button.orange:active, .ui.button.active.orange {
    background-color: var(--orange-120) !important;
}

/* Rose */
.ui.button.pink {
    background-color: var(--pink-100) !important;
}

.ui.button.pink:hover:not(:active) {
    background-color: var(--pink-110) !important;
}

.ui.button.pink:active, .ui.button.active.pink {
    background-color: var(--pink-120) !important;
}

/* Violet */
.ui.button.purple {
    background-color: var(--purple-100) !important;
}

.ui.button.purple:hover:not(:active) {
    background-color: var(--purple-110) !important;
}

.ui.button.purple:active, .ui.button.active.purple {
    background-color: var(--purple-120) !important;
}

/* Brun */
.ui.button.brown {
    background-color: var(--brown-100) !important;
}

.ui.button.brown:hover:not(:active) {
    background-color: var(--brown-110) !important;
}

.ui.button.brown:active, .ui.button.active.brown {
    background-color: var(--brown-120) !important;
}

/* LABELS */
/* Primaire */
.label--primary {
    background-color: var(--primary-100) !important;
    color: var(--white-100) !important;
}

/* Secondaire */
.label--secondary {
    background-color: var(--secondary-100) !important;
    color: var(--white-100) !important;
}

/* Gris */
.ui.label.grey {
    background-color: var(--grey-100) !important;
}

/* Rouge */
.ui.label.red {
    background-color: var(--red-100) !important;
}

/* Vert */
.ui.label.green {
    background-color: var(--green-100) !important;
}

/* Bleu */
.ui.label.blue {
    background-color: var(--blue-100) !important;
}

/* Jaune */
.ui.label.yellow {
    background-color: var(--yellow-100) !important;
}

/* Orange */
.ui.label.orange {
    background-color: var(--orange-100) !important;
}

/* Rose */
.ui.label.pink {
    background-color: var(--pink-100) !important;
}

/* Violet */
.ui.label.purple {
    background-color: var(--purple-100) !important;
}

/* Brun */
.ui.label.brown {
    background-color: var(--brown-100) !important;
}