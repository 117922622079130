@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css';
@import '../styles/animations.css';
@import '../styles/style-guide.css';

*:not(i, .fa) {
  font-family: -apple-system, BlinkMacSystemFont, Sofia, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
  width: 100%;
}

.grality-background {
  background: url('../resources/images/login-signup-background.jpg');
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow-x: hidden;
  box-shadow: inset 0 0 0 1000px rgb(13 72 13 / 20%);
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#navbar {
  border-radius: 0 !important;
}

.leaflet-container {
  width: 100%;
  height: 100vh;
  max-width: 100%;
  max-height: 100vh;
}

#mapContainer,
#map,
#projectMapContainer,
#projectMap {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
}

.leaflet-control-simpleMapScreenshoter {
  border: none !important;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background-color: var(--white-100);
  border-radius: 5px;
  padding: 3px 3px 1px 3px;
}

.leaflet-control-simpleMapScreenshoter:hover {
  background-color: #f5f5f5;
}

.leaflet-control-simpleMapScreenshoter-btn {
  background-color: transparent !important;
  border: none !important;
}

.mt-0px {
  margin-top: 0px !important;
}

.text-primary {
  color: var(--primary-100) !important;
}

.text-light {
  color: var(--white-100) !important;
}

.bg-primary {
  background-color: var(--primary-100) !important;
  color: var(--white-100) !important;
}

.btn-primary {
  background-color: var(--primary-100) !important;
  color: var(--white-100) !important;
}

.data-changed {
  background-color: var(--primary-100) !important;
  color: var(--white-100) !important;
}

.leaflet-google-mutant {
  height: 100% !important;
}

.leaflet-popup-content>p,
.leaflet-popup-content>h3 {
  margin: 0 !important;
}

.leaflet-popup-content>div {
  margin-top: 5px !important;
  width: 100% !important;
  text-align: right !important;
}

.rdt_Pagination {
  -webkit-justify-content: flex-start !important;
}

.rdt_Table {
  height: auto !important;
}

.ic-line::before {
  display: block;
  content: '';
  background-image: url('../resources/icons/line.png');
  background-size: 17px;
  margin-top: 3px;
  height: 17px;
  width: 17px;
}

.print::after,
.download::after {
  display: none !important;
}

.leaflet-tooltip.class-tooltip,
.tooltip-project-label {
  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
}

.leaflet-tooltip-top.class-tooltip::before,
.leaflet-tooltip-bottom.class-tooltip::before,
.leaflet-tooltip-left.class-tooltip::before,
.leaflet-tooltip-right.class-tooltip::before {
  opacity: 0 !important;
}

.tooltip-project-label {
  color: black !important;
  font-size: 18px !important;
  font-weight: bolder !important;
  padding: 0 5px !important;
  border-radius: 10px !important;
  pointer-events: none !important;
  background-color: white !important;
  opacity: 0.5 !important;
}

.tooltip-reference {
  color: black !important;
  font-size: 12px !important;
  font-weight: bolder !important;
  text-align: center;
  pointer-events: none !important;
  cursor: grab;
  text-shadow: 2px 0 0 var(--white-100), -2px 0 0 var(--white-100), 0 2px 0 var(--white-100), 0 -2px 0 var(--white-100), 1px 1px var(--white-100), -1px -1px 0 var(--white-100), 1px -1px 0 var(--white-100), -1px 1px 0 var(--white-100);
}

.description {
  position: relative;
}

.data-table-extensions {
  position: absolute;
  width: 105px !important;
  top: 90px;
  right: 2%;
  z-index: 1000;
}

.leaflet-default-marker-icon {
  pointer-events: none !important;
}

.color-circle {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  border: solid 1px var(--white-40);
}

.rdt_TableRow div .ui.input>input {
  text-align: center !important;
}

.modified-value {
  color: red;
}

#modal-content {
  height: 100%;
  padding: 10px;
}

.modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modal-content-body {
  margin-top: auto;
  padding: 0px 1px;
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  height: 0px;
}

.modalNavbar {
  display: flex;
  border-bottom: 1px solid var(--grey-30);
  margin-top: 6px;
}

.modalNavbar>.left {
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-wrap: nowrap;
  border-bottom: none !important;
}

.modalNavbar>.right {
  margin-right: 5px;
  margin-left: 5px;
  display: flex !important;
  align-items: center;
}

.modalNavbar>.left::-webkit-scrollbar,
.modalNavbar>.left::-webkit-scrollbar-track,
.modalNavbar>.left::-webkit-scrollbar-thumb,
.modalNavbar>.left::-webkit-scrollbar-thumb:hover {
  height: 4px;
}

.userBaseProjects {
  display: flex;
  flex-wrap: nowrap;
  margin-left: 10px;
  margin-right: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 2px;
  flex: 1;
}

.userBaseProjects>.label {
  min-width: fit-content !important;
}

.image-gallery-slide {
  background-color: rgba(0, 0, 0, 0.9);
}

.image-gallery-slide img {
  width: 500px;
  height: 500px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.fullscreen .image-gallery-slide img {
  width: 100%;
  height: 100vh;
}

#tasks-list,
#notifications-list {
  height: 500px;
  max-height: 60vh;
  width: 350px;
  overflow-y: auto;
  padding: 1px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.toolbarClassName,
.editorClassName {
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  border-radius: 3px !important;
}

.editorClassName {
  padding: 0px 10px;
  min-height: 200px;
  max-height: 200px;
  line-height: 5px;
  cursor: text;
}

.ui.form .field {
  margin: 2px 0 !important;
}

.hover {
  cursor: pointer;
}

.leaflet-control-locate {
  display: none;
}

.leaflet-layerstree-header-name {
  padding-left: 5px;
}

.clndr-calendars-wrapper>div {
  display: flex;
  flex-direction: column-reverse;
}

.clndr-control {
  margin-top: 10px;
  margin-bottom: 0 !important;
}

.notCurrentRecurrence {
  color: grey !important;
}

.gallery-thumbnail-container {
  padding: 0 !important;
}

.gallery-thumbnail-container>.ui.dropdown {
  position: absolute;
  top: 5px;
  right: 1px;
  padding: 5px !important;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
}

.gallery-thumbnail-container>.ui.dropdown:hover {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
}

.gallery-thumbnail-container>.ui.dropdown .menu {
  margin-top: 4px !important;
}

.gallery-thumbnail {
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 5px;
  background-color: black;
  text-align: center !important;
}

.gallery-thumbnail img {
  height: 100%;
  max-width: 100%;
  transition: .2s all .2s;
}

.ui.vertical.tabular.menu {
  background-color: rgb(243, 243, 243) !important;
}

.ui.vertical.tabular.menu .item:hover {
  background-color: rgb(230, 230, 230) !important;
}

div.ui.mini.statistic.subscriptionPrice {
  margin: 5px;
}

div.ui.mini.statistic.subscriptionPrice>.label {
  font-size: 0.7rem !important;
}

div.ui.mini.statistic.subscriptionPrice>.value {
  font-size: 1.2rem !important;
  font-weight: bold;
}

.ui.menu .ui.dropdown .menu>.active.item {
  font-weight: 400 !important;
}

/* Pour le formulaire d'override */

label.overriding,
span.overriding,
li.overriding {
  color: var(--secondary-80) !important;
}

.overriding>.ui.fluid.action.input>div:not(.error)>input[type="number"],
.overriding>.ui.fluid.action.input>div.ui.selection.dropdown:not(.error),
.overriding>.ui.fluid.action.input>div:not(.error)>input[type="text"],
.overriding>.ui.fluid.action.input>div.ui.search.selection.dropdown:not(.error)>input[type="text"] {
  background-color: var(--secondary-80) !important;
}

.overriding>.ui.fluid.action.input>.ui.error>input[type="number"] {
  background-color: rgb(255, 246, 246) !important;
}

.ui.form .field .action-input div:not(.multiple) input,
.ui.form .fields .field .action-input div:not(.multiple) input {
  width: 100% !important;
}

.action-input:not(.left) .ui.selection.dropdown {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.action-input:not(.active) .ui.selection.dropdown {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.action-input.left button {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.ui.fluid.action.input.action-input>.ui.error.fluid.input>input {
  background-color: #FFF6F6 !important;
  color: #9f3a38 !important;
}

.tool-form {
  position: absolute;
  top: 5px;
  width: 250px;
  z-index: 850;
  border-radius: 6px;
  border: 1px solid var(--white-60);
  display: flex;
  max-height: calc(100% - 110px);
}

.tool-form .field:not(:last-child) {
  margin-bottom: 10px !important;
}

.tool-form .ui.form {
  margin: 0 auto;
  padding: 10px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.tool-form>.ui.form>div:first-child::-webkit-scrollbar,
.tool-form>.ui.form>div:first-child::-webkit-scrollbar-track,
.tool-form>.ui.form>div:first-child::-webkit-scrollbar-thumb,
.tool-form>.ui.form>div:first-child::-webkit-scrollbar-thumb:hover {
  width: 5px;
}

div.leaflet-control-container>div.leaflet-top.leaflet-right>div.leaflet-control-layers.leaflet-control.leaflet-control-layers-expanded {
  width: 195px !important;
}

.leaflet-top.leaflet-right:first-child:has(:not(#grality-attribution)) {
  margin-top: 25px;
}

.leaflet-bottom.leaflet-left {
  /* Sinon quand on repasse de Streetview à la carte, 'Google' est sur l'échelle */
  margin-bottom: 20px !important;
}

.mapPreview.hideAttribution .leaflet-bottom.leaflet-left {
  display: none;
}

.leaflet-bookmarks-control {
  margin-top: 10px !important;
  margin-right: 10px !important;
}

.leaflet-bookmarks-control .plus {
  width: 16px !important;
  padding-top: 1px !important;
}

.bookmarks-list-wrapper {
  max-height: 30vh !important;
}

.leaflet-bookmarks-form-coords {
  display: none !important;
}

.leaflet-bookmarks-form-input {
  float: left !important;
  width: 85% !important;
}

.leaflet-bookmarks-form-submit {
  margin-top: -1px !important;
}

#projectMapContainer.folder>#projectMap>.leaflet-control-container>div>.leaflet-bookmarks-control>.bookmarks-container>.add-bookmark-button {
  display: none !important;
}

#projectMapContainer.folder>#projectMap>.leaflet-control-container>div>.leaflet-bookmarks-control>.bookmarks-container {
  padding-bottom: 0 !important;
}

.leaflet-control-layers,
.leaflet-bar,
.pegman-control,
.ui.fluid.vertical.menu {
  box-shadow: none !important;
  border: 1px solid var(--white-60);
}

#base-layers-menu {
  position: absolute;
  bottom: 50px;
  display: flex;
  user-select: none;
  z-index: 900 !important;
}

#active-base-layer,
.base-layer-preview {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 50px;
  width: 50px;
  min-height: 50px;
  min-width: 50px;
  border-radius: 7px;
  overflow: hidden;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: 50% 50% !important;
}

#active-base-layer>div:first-child {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

#active-base-layer>img,
.base-layer-preview>img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

#base-layers-list {
  display: none;
  position: relative;
  flex-direction: column;
  padding: 10px;
  border-radius: 7px;
}

#active-base-layer:hover,
.base-layer-preview:hover {
  cursor: pointer;
}

.base-layer-preview:not(.no-hover):hover {
  border: 2px solid var(--primary-100);
}

.base-layer-preview.active {
  border: 2px solid var(--primary-100);
}

#legend-container {
  z-index: 900 !important;
  position: fixed;
  user-select: none;
}

.legend {
  padding: 0 !important;
  background-color: white;
  color: var(--black-100);
  width: 250px;
  padding: 10px;
  opacity: .9;
}

.legend:first-child,
.legend-body:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none !important;
}

.legend:last-child,
.legend-body:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.legend-title {
  display: flex;
  align-items: center;
  padding: 4px 8px 4px 8px;
  border-bottom: 1px solid var(--white-80);
  font-weight: bold;
}

.legend-title:hover {
  background-color: var(--white-90);
  cursor: pointer;
}

.legend-title.arrow::before {
  position: relative;
  margin-right: 5px;
  left: 5px;
  bottom: 3px;
  content: '';
  border: .5em solid transparent;
  border-left-color: var(--black-40);
  transform-origin: 0 50%;
  transform: rotate(90deg);
  transition: .3s 0s transform;
}

.legend-title.arrow.down::before {
  left: 3px;
  bottom: 0;
  transform: rotate(0deg);
  transition: .3s 0s transform;
}

.legend-body {
  background-color: var(--white-100);
  display: block;
  max-height: 275px;
  overflow-y: overlay;
  transition: .3s 0s max-height;
}

.legend-body.collapsed {
  max-height: 0;
  transition: .3s 0s max-height;
}

.legend-child {
  display: flex;
  align-items: center;
  padding: 0px 8px;
}

.legend-child.clickable:hover {
  cursor: pointer;
}

.legend-child:first-child {
  margin-top: 5px;
}

.legend-child:last-child {
  margin-bottom: 5px;
}

.legend-canva {
  margin-bottom: auto;
  border: solid 1px var(--grey-100);
}

.legend-child>span {
  margin-top: 1px;
}

.legend-counter {
  margin-left: auto !important;
  padding: 2px 3px !important;
  font-size: 12px;
  min-width: 30px;
  text-align: center;
}

.legend-counter.transparent {
  opacity: 0.4;
}

#error-handler {
  display: flex;
  height: 100%;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
}

#root>div>div.ui.center.aligned.middle.aligned.grid.grality-background>div>div>div:nth-child(1)>.maintenance-checkbox>label {
  font-weight: bold;
}

#root>div>div.ui.center.aligned.middle.aligned.grid.grality-background>div>div>div:nth-child(1)>.maintenance-checkbox>label::before {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

#root>div>div.ui.center.aligned.middle.aligned.grid.grality-background>div>div>div:nth-child(1)>.maintenance-checkbox.checked>label::before {
  background-color: rgba(54, 0, 0, 0.8) !important;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: none;
}

.leaflet-control-toolbar.leaflet-toolbar-0 {
  display: none !important;
}

.highlightedBackgroundImage {
  border: 20px solid #3ea8f4;
  border-radius: 25px;
}

#projectMap>div.leaflet-pane.leaflet-map-pane>div.leaflet-pane.leaflet-marker-pane>img {
  background-image: none;
}

.shepherd-header {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.shepherd-header>h3 {
  font-weight: bold !important;
}

.shepherd-header,
.shepherd-button-primary {
  background-color: var(--primary-100) !important;
}

.shepherd-element[data-popper-placement^=right]:not(.remove-margin) {
  margin-left: 15px !important;
}

.shepherd-element[data-popper-placement^=left]:not(.remove-margin) {
  margin-right: 15px !important;
}

.shepherd-element[data-popper-placement^=top]:not(.remove-margin) {
  margin-bottom: 15px !important;
}

.shepherd-element[data-popper-placement^=bottom]:not(.remove-margin) {
  margin-top: 15px !important;
}

#add-tree-area {
  position: fixed !important;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20% !important;
  height: 45vh !important;
  z-index: -1 !important;
}

.floating-right {
  float: right !important;
}

.react-mention--error__input {
  color: #9f3a38 !important;
  background-color: rgb(255, 246, 246) !important;
}

div.menu.transition.left.visible>div.scrolling.menu.transition {
  width: 100% !important;
}

#export-preview {
  width: 100%;
  max-height: 70vh;
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  overflow: hidden;
  border: none !important;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

#export-preview>img {
  height: 100%;
  max-width: 100%;
  -webkit-user-drag: none;
}

.leaflet-bottom.leaflet-right {
  z-index: 999;
}

.leaflet-pegman,
.leaflet-bookmarks-control:not(.expanded) {
  width: 28px !important;
  height: 28px !important;
}

.leaflet-bookmarks-control {
  font-size: 8pt !important;
  padding: 7px 5px !important;
}

.leaflet-control-layers-expanded {
  position: fixed;
  right: 0;
  z-index: 801;
}

.leaflet-control-layers>a,
.leaflet-control-measure>a {
  width: 26px !important;
  height: 26px !important;
  background-size: 18px 18px !important;
}

.leaflet-control-measure>a {
  background-size: 16px 16px !important;
}

#duplicate-form-checkboxes>.button {
  margin-left: 3px;
}

#datatable {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#datatable>header {
  max-height: 75px;
}

#datatable>.igLxal {
  flex-grow: 1;
}

.link:hover {
  color: var(--primary-100) !important;
}

.link.disabled:hover {
  color: inherit !important;
  cursor: default !important;
}

.leaflet-zoom-box {
  display: none !important;
}

.leaflet-crosshair {
  cursor: default !important;
}

.leaflet-lasso-active.leaflet-crosshair {
  cursor: crosshair !important;
}

iframe[name='JSD widget'] {
  z-index: 1000 !important;
}

#toolbar {
  z-index: 1000;
  width: 40px;
  transition: width 500ms;
  overflow-x: hidden !important;
}

#toolbar>.buttons {
  overflow: overlay;
  display: flex;
  flex-grow: 1;
  overflow-x: hidden !important;
}

#toolbar button {
  padding: 6px 0;
  font-size: 13pt;
  width: 100%;
  border-radius: 0px;
  display: flex;
  background-color: transparent !important;
  color: var(--black-80) !important;
  min-height: fit-content;
}

#toolbar span {
  white-space: nowrap;
}

#toolbar.expanded {
  width: 300px;
}

#toolbar.expanded button {
  align-items: center;
}

#toolbar span {
  font-size: 13pt;
  margin-left: 7px;
  text-align: left;
}

#toolbar>.buttons::-webkit-scrollbar,
#toolbar>.buttons::-webkit-scrollbar-track,
#toolbar>.buttons::-webkit-scrollbar-thumb,
#toolbar>.buttons::-webkit-scrollbar-thumb:hover {
  width: 0px;
}

#toolbar button.active {
  color: var(--primary-100) !important;
}

#toolbar button.active span {
  color: var(--primary-100) !important;
}

.divider-pointer {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 8px solid var(--primary-100);
  margin: -1px auto 1px auto;
}

.custom-divider--vertical {
  width: 2px;
  height: 'auto';
  margin: 0 10px;
}

#map-segment {
  border: none !important;
}

#project-list>div>div>div>.project-list-row {
  padding: 1rem 0 !important;
  border-bottom: 1px solid var(--white-70);
}

#project-list.simplified>div>div>div>.project-list-row {
  padding: 0.5rem 0 !important;
}

#project-list.simplified>div>div>div>.project-list-row:nth-of-type(1) {
  padding-top: 1rem !important;
}

.mapPreview>.leaflet-google-mutant>div>.gm-style>div:nth-child(14),
.mapPreview .gmnoprint,
.mapPreview .leaflet-control-attribution {
  display: none !important;
}

.opened-project {
  color: var(--primary-100) !important;
  cursor: pointer;
}

#dropZone {
  z-index: 9999 !important;
}

.my-masonry-grid {
  display: flex;
  margin-left: -10px;
  width: auto;
}

.my-masonry-grid_column {
  background-clip: padding-box;
}

.my-masonry-grid_column {
  padding-left: 10px;
  /* gutter size */
}

.my-masonry-grid_column>div {
  margin-bottom: 5px;
}

.my-masonry-grid_column>div>img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.geosearch-input {
  position: absolute;
  top: 80px;
  left: 50%;
  z-index: 999;
  width: 300px;
  max-width: 50%;
  transform: translate(-50%);
}

.geosearch-input>div,
.geosearch-input .ui.dropdown {
  width: 100%;
}

.geosearch-input i {
  display: none !important;
}

.leaflet-left {
  z-index: 998;
}

#modal-content div.ui.checked.toggle.checkbox label[for=parent-properties-toggle]::before {
  background-color: var(--secondary-80) !important;
}

#photo-carousel {
  z-index: 11000;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
}

.slick-arrow.slick-prev {
  position: fixed;
  top: 50%;
  left: 25px;
  z-index: 9999;
}

.slick-arrow.slick-next {
  position: fixed;
  top: 50%;
  right: 38px;
  z-index: 9999;
}

.slick-arrow.slick-prev:before,
.slick-arrow.slick-next:before {
  font-size: 32px !important;
}

.background-images-order {
  margin-top: 5px;
  background-color: var(--white-90);
  border-radius: 10px;
  padding: 7px;
}

.background-images-order>div {
  cursor: grab;
}

.background-images-order>div>div:first-child {
  background-color: var(--white-80);
  border-radius: 10px 10px 0 0;
  padding: 5px;
}

.background-images-order>div:not(:first-child) {
  margin-top: 10px;
}

.background-images-order>div>.background-image {
  width: 100%;
  aspect-ratio: 16 / 8;
  overflow: hidden;
  background-color: var(--white-70);
  text-align: center;
  border-radius: 0 0 10px 10px;
  margin-left: auto;
  margin-right: auto;
}

.background-images-order>div>.background-image>img {
  height: 100%;
}

.sortable-ghost {
  cursor: grabbing !important;
}

.background-image {
  -webkit-user-drag: none !important;
  pointer-events: none !important;
}

.ldi .leaflet-pane .leaflet-overlay-pane img.background-image.disabled {
  pointer-events: none !important;
}

#grality-attribution {
  color: white;
  font-size: 14pt;
  text-shadow: -1px 0 1px var(--black-80), 0 1px 1px var(--black-80), 1px 0 1px var(--black-80), 0 -1px 1px var(--black-80);
  margin: 0 5px;
  user-select: none;
  cursor: pointer;
}

#theme-preview {
  border: 1px solid var(--grey-80);
  border-radius: 5px;
  overflow: hidden;
}

.tui-colorpicker-palette-button,
.tui-colorpicker-palette-preview {
  border: solid 2px var(--black-100) !important;
}

.contexify_theme-light,
.contexify_theme-dark {
  --contexify-zIndex: 9999 !important;
  --contexify-activeItem-bgColor: var(--primary-100);
}

.contexify_item>div:has(.delete) {
  color: var(--red-100) !important;
}

.contexify_item>div:has(.delete):hover {
  color: var(--white-100) !important;
  background-color: var(--red-100) !important;
  border-radius: 5px !important;
}

.custom-fields-header {
  display: flex;
  padding: 10px;
  margin-bottom: 0;
  border-radius: 5px 5px 0 0;
  border-bottom: 1px solid var(--grey-100);
}

.custom-fields-header>h4 {
  margin: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.custom-fields-header>div>.field>div>input,
.list-of-fields>div>.category>.header>.field>div>input {
  padding-left: 5px !important;
  height: 30px;
}

.list-of-fields {
  position: relative;
  height: 100% !important;
  border-radius: 0 0 5px 5px;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.list-of-fields>div>.category {
  position: relative;
}

.list-of-fields>div>.category>.header {
  font-size: 18px;
  font-weight: bolder;
  margin: 0 !important;
}

.list-of-fields>div>.category>.field {
  margin: 0;
  padding: 5px 10px;
  font-size: 16px;
  user-select: none;
}

.list-of-formulas>.formula {
  width: 170px;
}

.list-of-fields>.field:hover,
.list-of-fields>div>.category>.field:hover,
.list-of-formulas>.formula:hover {
  cursor: pointer;
}

.list-of-fields>.field.required,
.list-of-fields>div>.category>.field.required,
.list-of-fields>div>.category>.field.required:hover {
  color: var(--grey-90);
  cursor: not-allowed;
}

.list-of-fields>div>.category>.field.selected,
.list-of-formulas>.formula.selected {
  background-color: var(--primary-100);
}

.list-of-fields>div>.category>.field.selected:hover,
.list-of-formulas>.formula.selected:hover {
  background-color: var(--primary-110);
}

.list-of-fields>div>.category>.field.selected:active,
.list-of-formulas>.formula.selected:active {
  background-color: var(--primary-120);
}

.list-of-formulas>.formula {
  border-radius: 5px;
  padding: 10px;
}

.list-of-formulas>.formula {
  margin-bottom: 5px;
}

.list-of-formulas>.formula:last-child {
  margin-bottom: 0;
}

.layer-measurearea,
.layer-measure-resultarea,
.layer-measureboundary,
.layer-measure-resultline {
  fill-opacity: 0.4;
  stroke-width: 4;
}

.custom-breadcrumb {
  display: flex !important;
  user-select: none;
}

.custom-breadcrumb>.sections {
  display: flex;
  align-items: center;
  overflow-x: auto;
}

.custom-breadcrumb>.sections>svg {
  margin: 0 5px;
}

.custom-breadcrumb>button {
  max-height: 26px !important;
}

.custom-breadcrumb>.sections>div {
  display: flex;
  align-items: center;
}

.custom-breadcrumb>.sections>div>svg {
  margin: 0 5px;
}

.custom-breadcrumb>.sections>div>.section {
  display: flex;
  flex-wrap: nowrap !important;
  align-items: center;
  padding: 5px !important;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
}

.custom-breadcrumb>.sections>div>.section:not(.active) {
  cursor: pointer;
}

.custom-breadcrumb>.sections>div>.section.active {
  color: var(--primary-100) !important;
  font-weight: bolder;
}

.custom-breadcrumb>.sections>div>.section>svg {
  height: 17px;
  width: 17px;
}

.header.folder>svg {
  color: var(--secondary-90) !important;
}

.header.project>svg {
  color: var(--secondary-50) !important;
}

.tree-structure-item {
  user-select: none;
}

.tree-structure-item.disabled {
  color: var(--grey-70) !important;
}

.tree-structure-item>.item>div {
  margin-right: 5px;
  padding: 0 5px;
  border-radius: 5px;
}

.tree-structure-item:not(.disabled)>.item>div:hover {
  background-color: var(--primary-100);
}

.tree-structure-item>.item>div {
  margin-top: auto;
  margin-bottom: auto;
}

#move-base-projects-dimmer>.content {
  display: flex;
  align-items: center;
  justify-content: center;
}

#selected-base-projects,
#tree-structure {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid var(--grey-100);
  flex: 1;
  width: 100%;
  overflow-y: auto;
}

.input-popup-form {
  display: flex;
  flex-direction: column;
}

.slick-slide>div>div {
  height: 100vh;
}

.slick-slide-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.suicr-input {
  width: 220px !important;
}

#dropdown-checkboxes-menu {
  position: absolute;
  margin-top: 7px;
  width: auto;
  padding: 5px;
  z-index: 1;
}

#dropdown-checkboxes-menu>#menu-items {
  max-height: 300px;
  overflow-y: auto;
}

#subscriptions {
  height: 100%;
  overflow: hidden !important;
  display: flex;
  flex-direction: column;
  margin: 0 30px;
}

#subscriptions>#labels>#ask-quote {
  border-radius: 10px;
  padding: 10px 0;
  margin-right: 10px;
  background-image: url('../resources/svgs/waves.svg');
  background-size: 650% 650%;
  background-position: center;
}

#subscriptions>#labels>#ask-quote>.top {
  padding-top: 0 !important;
}

#subscriptions>#labels {
  display: flex;
  overflow-y: auto;
}

#subscriptions>#labels>div {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 200px;
}

#subscriptions>#labels>div>.top {
  border-radius: 10px;
  padding: 10px 0;
  text-align: center;
}

#subscriptions>#labels>div>.top>h3 {
  color: var(--grey-100) !important;
}

#subscriptions>#labels>div.active>.top {
  background-image: url('../resources/svgs/waves.svg');
  background-size: 1000% 1000%;
  background-position: center;
  background-color: var(--primary-100);
  border-radius: 10px;
}


#subscriptions>#labels>div.active>.top>h3 {
  color: var(--white-100) !important;
}

#subscriptions>#labels>div>.bottom {
  text-align: center;
}

#subscriptions>#features {
  flex: 1;
  overflow-y: scroll;
}

.element-popup {
  pointer-events: none !important;
}

.overlay-header {
  border-radius: 10px;
}

.overlay-header.expanded {
  border-radius: 10px 10px 0 0;
}

.overlay-header,
.overlay-children>div {
  width: 100%;
  padding: 5px 8px;
  font-size: 12pt;
  display: flex;
  align-items: center;
}

.overlay-header:hover,
.overlay-children>div:hover {
  cursor: pointer;
}

.overlay-children>div:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.layer-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: auto;
}

.layer-list::-webkit-scrollbar,
.layer-list::-webkit-scrollbar-track,
.layer-list::-webkit-scrollbar-thumb,
.layer-list::-webkit-scrollbar-thumb:hover {
  width: 0px;
  height: 0px;
}

.ui.pointing.dropdown.thematic-map__options>.menu {
  margin-bottom: 3px;
}

.ui.pointing.dropdown.thematic-map__options>.menu:after {
  width: 0;
  height: 0;
}

.no-max-height {
  max-height: none !important;
}

#terms-of-use {
  display: flex;
  flex-direction: column;
}

#terms-of-use>small {
  margin-left: auto;
}

#terms-of-use>section:not(:first-of-type) {
  margin-top: 15px;
}

#terms-of-use>h2,
#terms-of-use>section>h4 {
  margin-top: 0;
}

#terms-of-use>section:not(:last-child) {
  margin-bottom: 20px;
}

#terms-of-use>section>div:not(:first-of-type)>h4,
#terms-of-use>section>div>div:not(:first-child)>h5 {
  margin-top: 10px !important;
}

#terms-of-use>section>h3:first-child {
  margin-bottom: 10px !important;
}

#terms-of-use>section>div>h4,
#terms-of-use>section>div>div>h5 {
  margin-bottom: 5px !important;
}

#terms-of-use {
  color: grey;
}

::-webkit-scrollbar {
  width: 7px !important;
  height: 7px !important;
}

.ui.list>.error::before {
  color: #9f3a38 !important;
}

.show-button,
.edit-button {
  justify-content: center;
  height: 20px;
  width: 20px;
  min-width: 20px;
  display: flex !important;
  align-items: center;
  align-self: center;
  margin-bottom: 3px !important;
}

.custom-range-picker {
  display: flex;
  align-items: center;
  border: 1px solid var(--grey-100);
  border-radius: 5px;
  padding: 4px 5px 3px 5px;
}

.ui.form .field.custom-range-picker {
  margin-left: 0.5em !important;
  margin-right: 0.5em !important;
}

.custom-range-picker>.field:first-of-type {
  margin: 0 5px 0 5px !important;
}

.custom-range-picker>.field:last-of-type {
  margin: 0 0 0 5px !important;
}

.custom-range-picker>.field>.input>i {
  display: none !important;
}

.custom-range-picker>.field>.input>input,
.custom-range-picker>.field>.input>input:focus {
  padding: 5px 0 0 0 !important;
  border: none !important;
  border-bottom: 1px solid var(--white-100) !important;
  border-radius: 0 !important;
}

.custom-range-picker>.field>.input>input,
.custom-range-picker>.field>.input>input::placeholder {
  text-transform: lowercase;
}

.custom-range-picker>.field {
  overflow-x: hidden !important;
}

.vertical-gauge-card {
  transform: rotate(270deg);
  height: 222px;
  aspect-ratio: 1/1;
  text-align: justify;
}

.vertical-gauge-card::after {
  content: '';
  width: 100%;
  display: inline-block;
}

.vertical-gauge-card>div.ui.progress.progress--vertical {
  margin-right: 0 !important;
}

.vertical-gauge-card>div.ui.progress.progress--vertical:not(:first-child) {
  margin-left: auto !important;
}

.vertical-gauge-card>div.ui.progress.progress--vertical>.bar>.progress {
  transform: rotate(90deg);
}

.photos--cut-down {
  padding: 15px;
  border-radius: 15px;
  border: solid 2px;
}

#connected-users {
  position: absolute;
  left: 0;
  z-index: 800;
}

#connected-users>div {
  background-color: var(--white-100);
  border: 2px solid var(--black-100);
}

#connected-users-list {
  position: relative;
  display: flex;
  flex-direction: column;
  user-select: none;
  border-radius: 5px;
  height: auto;
  width: 200px;
  overflow: hidden;
  transition: .5s 0s all;
}

#connected-users-list>div>p {
  color: var(--grey-80) !important;
}

#subscription-dropdown {
  font-size: 22px;
  font-weight: bold;
  margin-top: -6px;
  padding: 6px 8px;
  min-height: 0px;
  min-width: 100%;
}

#subscription-dropdown .item {
  padding: 8px 16px !important;
}

#subscription-dropdown.active>div {
  font-weight: bold !important;
}

#subscription-dropdown>i {
  padding: 6px;
}

.progress-bar:not(.not-animated)>div {
  animation-name: width-animation;
  animation-duration: 1s;
}

.progress-bar>div>div:hover {
  filter: brightness(1.1);
}

.formula-card {
  background-color: var(--white-90);
  border: 1px solid var(--grey-40);
}

.formula-card>div>img {
  filter: grayscale(.4);
}

.formula-card>div>h4.disabled {
  color: var(--grey-80) !important;
}

#app-version {
  color: var(--grey-80) !important;
  font-size: 12px;
}

.dashboard-project,
.dashboard-support>a {
  display: flex;
  align-items: center;
  text-align: left;
  margin: 5px 0;
  padding: 7px;
  border-radius: 5px;
  cursor: pointer;
  color: var(--black-100);
  background-color: var(--grey-30);
  transition: 50ms background-color 50ms;
}

.dashboard-project:hover,
.dashboard-support>a:hover {
  background-color: var(--primary-100) !important;
}

.timeline {
  margin: 15px 40px 35px 40px;
  height: 4px;
  flex: 1;
  background-color: var(--grey-30);
}

.timeline-pagination {
  padding: 5px 8px !important;
  border-radius: 100% !important;
}

.timeline-progress {
  width: 0;
  height: 100%;
  background-color: var(--primary-100);
  transition: width .1s .1s;
}

.timeline-items {
  margin-left: -10px;
  margin-right: -10px;
  margin-top: -9px;
  display: flex;
  justify-content: space-between;
}

.timeline-item {
  position: relative;
  cursor: pointer;
}

.timeline-item::before {
  content: '';
  width: 15px;
  height: 15px;
  background-color: var(--grey-30);
  display: block;
  border-radius: 100%;
}

.timeline-item.disabled::before {
  background-color: var(--grey-100) !important;
}

.timeline-item.disabled>.timeline-content {
  color: var(--grey-100) !important;
}

.timeline-content {
  position: absolute;
  top: 17px;
  left: 50%;
  transform: translateX(-50%);
  color: var(--black-100) !important;
  width: 50px;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.timeline-item.active::before {
  background-color: var(--primary-100);
}

.timeline-item.active.disabled::before {
  background-color: var(--primary-120) !important;
}

.progress-button {
  position: relative;
  overflow: hidden;
}

.progress-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.progress-button.custom-progress::before {
  animation: progress 5s ease-in-out forwards;
}

.progress-button.custom-progress.paused::before {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}

.password>.ui.action>.ui.icon.button {
  color: var(--grey-100);
  background-color: transparent;
  border-top: 1px solid var(--grey-50);
  border-right: 1px solid var(--grey-50);
  border-bottom: 1px solid var(--grey-50);
}

input,
.ui.action.input:not([class*="left action"])>input {
  border-right: none !important;
}

.field.error .visibility-button i {
  color: var(--grey-100) !important;
}

.external-auth-buttons {
  display: flex;
  gap: 10px;
}

.external-auth-buttons>div {
  width: 100% !important;
}

.external-auth-buttons>div>button {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
}

#new-window-container {
  background-color: #f3f3f3 !important;
}

#new-window-container>div>h1 {
  color: var(--black-100) !important;
}

#new-window-container>div>form .ui.input,
#new-window-container>div>form input,
#new-window-container>div>form input[type=password] {
  color: var(--black-100) !important;
  background-color: var(--white-100) !important;
}

#new-window-container .form {
  background-color: #f3f3f3 !important;
}

.button--urbasense {
  background-color: #55C277 !important;
}

.button--urbasense:active,
.button--urbasense.active {
  background-color: #3aa45b !important;
}

.button--urbasense:hover:not(:active) {
  background-color: #42b867 !important;
}

.import-step-loader:before {
  border-color: rgb(0, 0, 0, 0.1) !important;
}

.import-step-loader:after {
  border-color: rgb(0, 0, 0, 0.15) transparent transparent !important;
}

.task-card,
.notification-card {
  padding: 10px;
  background-color: var(--white-90);
  border-radius: 5px;
}

.task-card>div:first-child,
.notification-card>div:first-child {
  display: flex;
  align-items: center;
}

.task-card>div:first-child>svg,
.notification-card>div:first-child>svg {
  transform: rotate(0deg);
  transition: .2s transform 0s;
}

.task-card>div:nth-child(2),
.notification-card>div:nth-child(2) {
  background-color: var(--white-80);
  border-radius: 5px;
  margin-top: 0;
  max-height: 0;
  word-wrap: break-word;
  overflow-x: hidden;
  overflow-y: auto;
  transition: .2s all 0s;
}

.task-card>div:nth-child(2)>div,
.notification-card>div:nth-child(2)>div {
  padding: 10px;
}

.task-card.opened>div:first-child>svg,
.notification-card.opened>div:first-child>svg {
  transform: rotate(90deg);
}

.task-card.opened>div:nth-child(2),
.notification-card.opened>div:nth-child(2) {
  margin-top: 5px;
  max-height: 300px;
}

#welcome-card {
  background-image: url('../resources/svgs/waves.svg');
  background-size: 1000% 1000%;
  background-position: center;
}

.leaflet-bottom.leaflet-left>div:last-of-type>div {
  bottom: -21px !important;
}

.icon-picker {
  position: relative;
}

.icon-picker>.icon-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  font-size: 12px;
  border-radius: 5px;
  cursor: pointer;
}

.icon-picker>.icon-preview.disabled {
  cursor: default !important;
}

.icon-picker>#icon-picker-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 220px;
  background-color: var(--grey-60);
  color: var(--white-100);
  padding: 5px;
  border-radius: 5px;
  overflow: hidden;
  z-index: 1000;
}

#icon-picker-container>.icon-list {
  display: flex;
  flex-direction: column;
  height: 250px;
  margin-top: 5px;
  overflow: auto;
}

#icon-picker-container>.icon-list>p {
  font-size: 1rem !important;
  margin-left: auto;
  margin-right: auto;
}

#icon-picker-container>.icon-list>div>div>div>.icon-item {
  flex-basis: 20%;
  text-align: center;
  border-radius: 5px;
}

#icon-picker-container>.icon-list>div>div>div>.icon-item.active {
  background-color: var(--primary-100);
}

#icon-picker-container>.icon-list>div>div>div>.icon-item:not(.active):hover {
  background-color: var(--grey-40);
  cursor: pointer;
}

#icon-picker-container>p {
  color: var(--white-100) !important;
}

.ui.dropdown .menu>.item.delete {
  color: var(--red-100) !important;
}

.ui.dropdown .menu>.item.delete:hover {
  background-color: var(--red-100) !important;
  color: var(--white-100) !important;
}

.ui.dropdown .menu>.item.subscription {
  color: var(--yellow-100) !important;
}

.ui.dropdown .menu>.item.subscription:hover {
  background-color: var(--yellow-100) !important;
  color: var(--white-100) !important;
}

.custom-grid>.row:before {
  width: 100% !important;
  margin: 0 !important;
}

.custom-grid>.row.headers {
  font-weight: bold;
  padding: 10px 5px !important;
}

.custom-grid>.row.headers>.column {
  margin: 0 !important;
}

.custom-grid>.row:not(:first-of-type) {
  padding: 5px !important;
}

.custom-grid>.row:not(:first-of-type)>.column {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-grid>.row:first-of-type:before {
  box-shadow: none !important;
}

.custom-grid>.row:not(:first-of-type):before {
  background-color: var(--white-80) !important;
}

.role-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-y: auto;
}

.role-list>div {
  padding: 8px;
  margin-right: 10px;
  border-radius: 5px;
}

.role-list>div:hover {
  cursor: pointer;
}

.custom-search>div {
  border: 1px solid var(--grey-100);
  border-radius: 5px !important;
}

.custom-search>div>input {
  border: none !important;
}

.search>.results {
  border: 1px solid var(--black-50) !important;
  border-radius: 5px !important;
  width: 25em !important;
}

.search>.results>.result:hover {
  background-color: var(--grey-20) !important;
}

.search>.results>.result>div>div:last-of-type>div:first-of-type {
  color: var(--grey-60);
}

.search>.results>.result>div>div:last-of-type>div:last-of-type {
  color: var(--white-100);
  padding: 3px;
  border-radius: 5px
}

div.dropdown.template-select>div.divider.default.text {
  width: 100% !important;
}

.linked-element-card>.header>div>h3 {
  color: var(--white-100) !important;
}

.linked-element-card>.content {
  background-color: var(--white-100) !important;
}

#beta-label {
  background: linear-gradient(90deg, rgba(81, 150, 54, 1) 0%, rgba(104, 189, 70, 1) 100%);
  color: white;
  font-weight: normal !important;
  margin-left: 10px;
  border-radius: 15px;
}

#ai-dimmer>.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#add-wms {
  margin-bottom: 0 !important;
}

.pulsing-circle {
  stroke: var(--white-100);
  fill: var(--blue-100);
  stroke-width: 2px;
  stroke-opacity: 1;
}

.pulsing-circle.pulse {
  fill: white;
  fill-opacity: 0;
  transform-origin: 50% 50%;
  animation-duration: 2s;
  animation-name: pulse;
  animation-iteration-count: infinite;
}

thead.suicr-day-view-header>tr:nth-child(1)>th:nth-child(2)>span,
thead.suicr-month-view-header>tr:nth-child(1)>th:nth-child(2)>span,
thead.suicr-year-view-header>tr:nth-child(1)>th:nth-child(2)>span,
thead.suicr-day-view-header>tr:nth-child(1)>th>i,
thead.suicr-month-view-header>tr:nth-child(1)>th>i,
thead.suicr-year-view-header>tr:nth-child(1)>th>i {
  background-color: #2185d0 !important;
  border-radius: 5px !important;
  height: 30px !important;
}

thead.suicr-day-view-header>tr:nth-child(1)>th:nth-child(2)>span,
thead.suicr-month-view-header>tr:nth-child(1)>th:nth-child(2)>span,
thead.suicr-year-view-header>tr:nth-child(1)>th:nth-child(2)>span {
  padding: 8px 8px !important;
  text-wrap: nowrap !important;
}

thead.suicr-day-view-header>tr:nth-child(1)>th:nth-child(2)>span:hover,
thead.suicr-month-view-header>tr:nth-child(1)>th:nth-child(2)>span:hover,
thead.suicr-year-view-header>tr:nth-child(1)>th:nth-child(2)>span:hover,
thead.suicr-day-view-header>tr:nth-child(1)>th>i:hover,
thead.suicr-month-view-header>tr:nth-child(1)>th>i:hover,
thead.suicr-year-view-header>tr:nth-child(1)>th>i:hover {
  background-color: #1f77ba !important;
}

thead.suicr-day-view-header>tr:nth-child(1)>th>i,
thead.suicr-month-view-header>tr:nth-child(1)>th>i,
thead.suicr-year-view-header>tr:nth-child(1)>th>i {
  padding: 5px 10px 5px 10px !important;
  width: 30px !important;
}

thead.suicr-day-view-header>tr:nth-child(1)>th:first-child>i {
  padding-left: 9px !important;
}

thead.suicr-day-view-header>tr:nth-child(1)>th:last-child>i {
  padding-right: 9px !important;
}

.extra-value {
  margin-left: 5px;
  font-size: 22px;
  cursor: pointer;
}

.extra-value:hover {
  color: var(--green-100) !important;
}

#temp-height>label,
#temp-height>span {
  color: var(--grey-80) !important;
}

.text-area--error {
  border-radius: 5px;
  background-color: #fff1f1;
  color: red;
  margin: 5px 0;
  width: 100%;
  font-size: 10pt;
  height: 14vh;
  padding: 10px;
}

#Yj5V0rzR>div.menu.transition>.organization-item {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  cursor: pointer;
}

.fields>.field>.field.grow {
  flex-grow: 1 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.base-layers-buttons {
  margin-left: auto !important;
}

.base-layers-buttons>button {
  padding: 5px !important;
}

.react-contextmenu-item:hover,
.react-contextmenu-item:focus {
  background-color: var(--primary-100) !important;
}

#anchor-buttons>.ui.button {
  border-radius: 0 !important;
}

#anchor-buttons {
  border-radius: 4px !important;
  overflow: hidden !important;
}

@keyframes pulse {
  from {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }

  to {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
}

@keyframes progress {
  0% {
    left: -100%;
  }

  100% {
    left: 0%;
  }
}

@keyframes width-animation {
  0% {
    width: 0px;
  }

  100% {
    width: calc(100% - 1px);
  }
}

@media (max-height: 900px) {
  .legend-body {
    max-height: 200px;
  }
}

@media (max-width: 1150px) {
  #subscriptions>#labels>#ask-quote {
    margin-right: 5px;
  }

  #subscriptions {
    margin: 0;
  }

  #subscriptions>small {
    display: none !important;
  }

  #subscriptions>#labels {
    margin: 10px 10px 0 10px !important;
    padding: 5px;
  }

  #subscriptions>#labels>div {
    padding: 5px !important;
    cursor: pointer;
  }

  #subscriptions>#labels>div.selected {
    border: 1px solid var(--primary-100) !important;
    border-radius: 10px;
    cursor: initial;
  }

  #subscriptions>#labels>div>.bottom {
    margin-top: 10px !important;
  }

  #subscriptions>h3 {
    margin: 0 10px !important;
    margin-bottom: 10px !important;
  }

  #subscriptions>#features {
    margin: 0 10px !important;
  }

  #subscriptions>#features>div>div>.value:not(.selected) {
    display: none !important;
  }
}

@media (max-width: 1000px) {
  .formula-card {
    width: 46% !important;
  }


}

@media (max-width: 900px) {
  .formula-card {
    width: 100% !important;
  }
}

@media (max-width: 767px) {
  .formula-card {
    width: 100% !important;
  }

  .trunks-form {
    flex-direction: column;
  }

  .trunks-form>div {
    align-self: initial !important;
    flex: 1 !important;
  }

  .trunks-form>div>button {
    width: 100% !important;
  }
}