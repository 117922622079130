#image-editor {
    z-index: 11000;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #eee;
}

.tui-image-editor-header {
    display: none;
}

.tui-image-editor-container {
    height: calc(100% - 56.25px) !important;
}

.tui-image-editor-container .tui-image-editor-main {
    top: 0;
}

.tui-image-editor {
    left: -60px;
}

.tui-image-editor-controls {
    background-color: #EEEEEE !important;
}

.tui-image-editor-selectlist>li {
    margin-top: 1px !important;
}

.color-picker-control {
    height: unset !important;
    top: auto !important;
    bottom: 57px !important;
    left: -75px !important;
}

.tui-image-editor-container .tui-image-editor-help-menu.left {
    top: 45% !important;
    height: auto;
    border-radius: 5px;
    background-color: var(--white-100);
}

.tui-image-editor-menu {
    vertical-align: unset !important;
}

.tui-image-editor-controls {
    border-top: 1px solid rgb(193, 193, 193);
}